import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Works } from '../pages';
import { WorkFragment } from '../fragments';
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic';
import { IPageProps } from '../types/page-props';

type Props = IPageProps & {
  data: GatsbyTypes.CollectionQueryQuery;
};

const CollectionTemplate = (props: Props) => {
  const collection = props.data?.prismicWorkcollection;
  if (!collection) return null;

  const works = (collection.data?.works || [])
    .map((x) => x?.work?.document)
    .filter((x): x is GatsbyTypes.WorkFragmentFragment => x !== null);

  return (
    <Layout title={collection.data?.title}>
      <SEO title={collection.data?.title ?? ''} />
      {collection.data?.description && (
        <article className="my-4 prose">
          <RichText render={collection.data.description.raw} />
        </article>
      )}
      <Works works={works} />
    </Layout>
  );
};

export default withPreview(CollectionTemplate);

export const query = graphql`
  query CollectionQuery($uid: String!) {
    prismicWorkcollection(uid: { eq: $uid }) {
      id
      uid
      data {
        title
        description {
          raw
        }
        works {
          work {
            document {
              ... on PrismicWork {
                ...WorkFragment
              }
            }
          }
        }
      }
    }
  }
`;

CollectionTemplate.fragments = [WorkFragment];

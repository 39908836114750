import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { IPageProps } from '../types/page-props';

import { withUnpublishedPreview } from 'gatsby-source-prismic';
import WorkTemplate from '../templates/work';
import CollectionTemplate from '../templates/collection';

const NotFoundPage: React.FC<IPageProps> = (props) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    work: WorkTemplate,
    collection: CollectionTemplate,
  },
});

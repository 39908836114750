import { Images, WorkImages } from './WorkImages';
import Masonry from 'react-masonry-css';

import Img from 'gatsby-image';
import { Modal } from './Modal';
import React from 'react';
type Props = {
  images?: Images;
};

const Gallery: React.FC<Props> = ({ images }) => {
  const [activeIdx, setActiveIdx] = React.useState<number | null>(null);

  const handleClose = () => {
    setActiveIdx(null);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    800: 2,
    600: 1,
  };

  return (
    <div className="mt-16 ssr-hidden">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images?.map((image, index) => {
          const fluid = image?.image?.fluid;
          return (
            <button
              className="flex  flex-col cursor-pointer text-left overflow-hidden"
              key={index}
              type="button"
              style={{ width: '100%' }}
              onClick={() => setActiveIdx(index)}
            >
              {fluid && <Img fluid={fluid} className="w-full item" />}
              {image?.caption?.text && (
                <p className="my-2 uppercase leading-tight text-base text-gray-800">
                  {image?.caption.text}
                </p>
              )}
            </button>
          );
        })}
      </Masonry>

      <Modal
        isOpen={activeIdx !== null}
        onRequestClose={handleClose}
        className="fadeIn"
      >
        <div className="w-full h-full gallery relative">
          <WorkImages
            initialIdx={activeIdx ?? undefined}
            images={images}
            onRequestClose={handleClose}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Gallery;

import React from 'react';
import { graphql, Link } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import Masonry from 'react-masonry-css';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { IPageProps } from 'types/page-props';
import { WorkFragment } from '../fragments';

import Img from 'gatsby-image';

import { Modal } from '../components/Modal';
import { WorkImages } from '../components/WorkImages';

type Work = GatsbyTypes.WorkFragmentFragment;

export const Works = ({ works }: { works: readonly Work[] }) => {
  const [activeWork, setActiveWork] = React.useState<Work | null>(null);

  const handleClose = () => {
    setActiveWork(null);
  };
  const images = (activeWork?.data?.images ?? []).filter(
    (image) => image?.image?.fluid,
  );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    800: 2,
    600: 1,
  };

  return (
    <div className="ssr-hidden">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {works.map((work, index) => {
          const thumbnail = work.data?.preview?.thumbnail;
          return (
            <Link
              className=" break-words  flex-col flex w-full cursor-pointer text-left overflow-hidden"
              key={work.id ?? index}
              to={work?.fields?.hrefWithCollection ?? `/works/${work.uid}`}
            >
              {thumbnail && <Img fluid={thumbnail} />}
              {work.data?.title && (
                <p className="my-2 px-2 uppercase leading-tight text-base text-gray-800">
                  {work.data?.title?.text}
                </p>
              )}
            </Link>
          );
        })}
      </Masonry>

      <Modal isOpen={!!images.length} onRequestClose={handleClose}>
        <div className="w-full h-full works relative">
          <WorkImages images={images} onRequestClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
};

const IndexPage = (props: IPageProps & PageQuery) => {
  const { data } = props;

  return (
    <Layout>
      <SEO title="Home" />
      <Works works={data.works.nodes} />
    </Layout>
  );
};

export default withPreview(IndexPage);

interface PageQuery {
  data: GatsbyTypes.IndexPageQueryQuery;
  children?: React.ElementType;
}

export const query = graphql`
  query IndexPageQuery {
    works: allPrismicWork(filter: { tags: { in: ["main"] } }) {
      nodes {
        ...WorkFragment
      }
    }
  }
`;

IndexPage.fragments = [WorkFragment];

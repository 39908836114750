import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { WorkFragment } from '../fragments';
import { RichText } from 'prismic-reactjs';
import Gallery from '../components/Gallery';
import { withPreview } from 'gatsby-source-prismic';
import { IPageProps } from '../types/page-props';

type Props = IPageProps & {
  data: GatsbyTypes.WorkQueryQuery;
};

const WorkTemplate = (props: Props) => {
  const work = props.data.prismicWork;
  if (!work) return null;

  const images = (work?.data?.images ?? []).filter(
    (image) => image?.image?.fluid,
  );
  return (
    <Layout>
      <SEO title={work.data?.title?.text ?? ''} />
      <h1 className="inline-block leading-relaxed text-lg font-normal uppercase text-gray-800 hover:text-gray-700 transition linear duration-100 my-4">
        {work.data?.title?.text ?? ''}
      </h1>
      {work.data?.about?.raw && (
        <article className="prose">
          <RichText render={work.data.about.raw} />
        </article>
      )}
      <Gallery images={images} />
    </Layout>
  );
};

export default withPreview(WorkTemplate);

export const query = graphql`
  query WorkQuery($uid: String!) {
    prismicWork(uid: { eq: $uid }) {
      ...WorkFragment
    }
  }
`;

WorkTemplate.fragments = [WorkFragment];
